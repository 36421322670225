import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Section from "../components/containers/section"
import SectionBackground from "../components/containers/section-background"
import SectionContent from "../components/containers/section-content"
import Main from "../components/layout/main"
import { Columns, Content, Footer, Rows } from "../components/grid"
import Heading from "../components/content/heading"
import Copy from "../components/content/copy"
import Link from "../components/content/link"

function NotFoundPage() {
  return (
    <Main hidenav>
      <Section theme="dark">
        <SectionBackground>
          <StaticImage
            src="../images/common/fourohfour-bg.jpg"
            className="notfound__background"
          />
          <div className="notfound__background notfound__background--overlay" />
          <div className="absolute notfound__panda">
            <StaticImage src="../images/common/astrored.png" />
          </div>
          <div className="absolute notfound__key">
            <StaticImage src="../images/common/key.png" />
          </div>
        </SectionBackground>
        <SectionContent paddingBot="none" paddingTop="none" width="wide">
          <Rows>
            <Columns count="1,2" className="height--screen flex--align-center">
              <Content>
                <Heading className="notfound__heading" level={1}>
                  404
                </Heading>
                <Copy>
                  <p className="h3">This Page is Lost in Space</p>
                  <p>
                    We can’t find what you’re looking for, but the links below
                    may help.
                  </p>
                </Copy>
                <Footer>
                  <div className="flex">
                    <p>
                      <Link to="/">Home</Link> <span className="mx-xs">|</span>{" "}
                      <Link to="/resources/">Resource Hub</Link>{" "}
                      <span className="mx-xs">|</span>{" "}
                      <Link to="/blog/">Blog</Link>
                    </p>
                  </div>
                </Footer>
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export default NotFoundPage
